@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");



.slick-dots li.slick-active button:before {
  background: #f46c02 !important;
  width: 30px !important;
  height: 14px;
  border-radius: 10px !important;
  color: #f46c02 !important;
  margin: 0px -6px !important;
}

.slick-dots li button:before,
.slick-dots li button:focus:before {
  opacity: 1 !important;
}

.testimonial-area .slick-dots li button:before {
  height: 6px;
  width: 6px;
  border: 0;
  font-size: 0px;
  border-radius: 50%;
  background: #33415b;
  padding: 0;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* .testimonial-area .slick-current .testimonial-item .quote svg path {
  fill: #f46c02;
}

.testimonial-area .slick-current .testimonial-item {
  background: #3f56a3;
} */

.team-area .slick-arrow.slick-next {
  display: none !important;
}

.team-area .slick-arrow {
  right: -67px;
}

.team-area .slick-prev {
  left: -67px;
}

.team-area .slick-arrow.slick-prev {
  display: none !important;
}

.lds-ellipsis {
  width: 140px !important;
}

.lds-ellipsis span {
  margin: 5px;
}

.lds-ellipsis span:nth-child(1) {
  animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
}

.lds-ellipsis span:nth-child(2) {
  animation: ball-pulse-sync 0.6s -70ms infinite ease-in-out;
}

@keyframes ball-pulse-sync {
  33% {
    transform: translateY(10px);
  }

  66% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

.team-area .slick-dots li button:before,
.testimonial-2-active .slick-dots li button:before {
  color: transparent;
}

.team-area .slick-dots li.slick-active button:before,
.testimonial-2-active .slick-dots li.slick-active button:before {
  color: transparent !important;
  background-color: transparent !important;
}

.testimonial-2-active {
  position: relative !important;
}

.testimonial-2-active .slick-dots {
  position: absolute;
  right: -50px;
  text-align: end;
  bottom: 0;
}

.project-menu .nav-link.active {
  color: #fff;
  background: linear-gradient(90deg, #fad029 0%, #ff564f 65%);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff !important;
  background: linear-gradient(90deg, #fad029 0%, #ff564f 65%) !important;
  transition: all ease-in-out 0.2s;
}

.project-menu .nav-pills .nav-link:hover {
  color: black;
}

.iframevideo {
  height: 450px;
}

.modal-body {
  padding: 0 !important;
}

.productVideo {
  position: relative;
}

.productVideo .btn-close {
  position: absolute;
  background-color: white;
  box-shadow: 0 0 10px;
  right: 0;
  z-index: 9999999;
}

/* .maintitle {
  width: max-content;
  background: white;
  color: black;
  position: absolute;
  top: 15px;
  margin-bottom: 0px;
  text-transform: uppercase;
  left: 0px;
  padding: 6px 34px;
  border-radius: 0px 50px 50px 0px;
  box-shadow: 0 0 0 2px #ddd;
} */

.maintitle {
  width: max-content;
  background: white;
  color: white;
  position: absolute;
  top: 34px;
  font-size: 16px;
  margin-bottom: 0px;
  text-transform: uppercase;
  left: 34px;
  padding: 6px 12px;
  /* color: blue; */
  background: #3f56a3;
  border-radius: 6px;
  /* border-radius: 0px 50px 50px 0px; */
  /* box-shadow: 0 0 0 2px #ddd; */
  /* padding: 11px; */
}




.applynow:hover {
  border-color: #f46c02 !important;
}

.applybadge {
  background-color: #f46c02;
  color: white;
  height: 100%;
}

.specification span {
  background-color: #e4e4e4;
  margin-right: 10px;
  padding: 2px 16px;
  border-radius: 6px;
  color: #abb0b0;
  font-family: "Jost", sans-serif;
}

.jost {
  font-family: "Jost", sans-serif;
}

.accordion-item {
  margin-bottom: 30px;
  border: none;
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px #ede9fe;
  padding: 16px 0;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  box-shadow: transparent !important;
}

@media (max-width: 768px) {
  .sub-item-area .d-flex {
    flex-wrap: wrap;
  }

  .subportfolioimage {
    width: 130px;
  }
}

.header-nav.sticky .navbar-toggler .toggler-icon {
  width: 30px;
  height: 2px;
  background-color: #000 !important;
  margin: 5px 0;
  display: block;
  position: relative;
  transition: all 0.3s ease-out 0s;
}

.hero-play .w-100 {
  border: 2px solid white;
  border-radius: 36px;
  box-shadow: 0 0 8px white;
}

/* .myVideo {
  height: 100vh;
} */

@media (min-width: 1100px) {
  .myVideo {
    height: 100vh;
  }
}

@media (min-width: 769px) {
  .bannerarea {
    display: none;
  }
}

@media (max-width: 768px) {
  .videosection {
    display: none;
  }

  .sub-item-area {
    padding-top: 0px !important;
  }
}

.myVideo video {
  object-fit: fill;
}

.gradbtn {
  background-color: #f46c02 !important;
}

.gradbtn:hover {
  background: transparent !important;
  color: #f46c02 !important;
  border: 2px solid #f46c02;
}

.single-team-member .image::before {
  left: 0;
  right: 0;
  height: 0;
  content: "";
  width: 100%;
  opacity: 0.9;
  bottom: 135%;
  border-radius: 50%;
  position: absolute;
  -webkit-transform: scale(3);
  transform: scale(3);
  -webkit-transition: 0.5s;
  transition: 0.7s;
  background-color: #3f56a3;
}

.single-team-member {
  background-color: white;
  box-shadow: 0 0 10x;
  margin-bottom: 30px;
  text-align: center;
  position: relative;
  padding: 60px 30px;
  overflow: hidden;
}

.single-team-member .image img {
  border-radius: 50%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.9s ease 0s;
  transition: all 0.9s ease 0s;
}

.t-orange {
  color: #ff7448;
}

.single-team-member .social-links {
  left: 0;
  right: 0;
  opacity: 0;
  bottom: -35px;
  padding-left: 0;
  margin-bottom: 0;
  position: absolute;
  visibility: hidden;
  list-style-type: none;
  -webkit-transition: 0.5s;
  transition: 0.7s;
  background-color: #3f56a3;
}

.single-team-member:hover .social-links {
  margin-top: 5px;
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

.single-team-member:hover .image::before {
  height: 100%;
}

.social-links li:hover a {
  padding: 4px 10px;
  background: #f46c02;
  border-radius: 6px;
}

.h-200 {
  height: 200px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  /* ensure it's above other elements */
  background-color: rgb(178 173 172 / 63%);
  /* background-color: #eeeeee; */
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after {
  position: absolute;
  top: 0;
  content: "";
}

.jimu-primary-loading:before {
  left: -19.992px;
}

.jimu-primary-loading:after {
  left: 19.992px;
  -webkit-animation-delay: 0.32s !important;
  animation-delay: 0.32s !important;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after,
.jimu-primary-loading {
  background: #076fe5;
  -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  width: 13.6px;
  height: 32px;
}

.jimu-primary-loading {
  text-indent: -9999em;
  margin: auto;
  position: absolute;
  right: calc(50% - 6.8px);
  top: calc(50% - 16px);
  -webkit-animation-delay: 0.16s !important;
  animation-delay: 0.16s !important;
}

@-webkit-keyframes loading-keys-app-loading {

  0%,
  80%,
  100% {
    opacity: 0.75;
    box-shadow: 0 0 #076fe5;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #076fe5;
    height: 40px;
  }
}

@keyframes loading-keys-app-loading {

  0%,
  80%,
  100% {
    opacity: 0.75;
    box-shadow: 0 0 #076fe5;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #076fe5;
    height: 40px;
  }
}

.subservicecardsection img {
  height: 500px;
  object-fit: cover;
}

/* .backbtn {
  width: 45px;

  background-color: #076fe5;
  border-radius: 4px;
  transition: width 0.3s ease-in-out;
}



.backbtn:hover {
  width: 150px;
  animation: background-color-animation 0.3s ease-in-out forwards;
} */

.backbtn {
  background-color: #076fe5;
  border-radius: 4px;
}

.backbtn:hover {
  background-color: #f46c02;
  transition: all ease-in-out 0.5s;
}

@keyframes background-color-animation {
  from {
    background-color: initial;
    /* original background color */
  }

  to {
    background-color: #f46c02;
    /* target background color */
  }
}

.backbtn button i {
  color: white;
}

@media (max-width: 768px) {
  .subdetailimage img {
    height: 200px;
    margin-bottom: 20px;
  }

  .jobcontent {
    margin-top: 15px;
  }

  .specification {
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
  }
}

.subdetailimage img {
  height: 400px;
  object-fit: cover;
  margin-bottom: 20px;
}

.blogtype {
  position: absolute;
  top: 24px;
  right: 26px;
  background: #053262;
  padding: 6px 14px;
  color: white;
  border-radius: 24px 0;
}

.article-2-item {
  position: relative;
}





/* .blinkingbtn {


  border: 0px solid #6c8003;
}

@keyframes blink {

  0%,
  100% {
    background-color: #3f06ff;
  }

  50% {
    background-color: #f46c02;
  }
} */

.blinkingbtn {
  background-color: #053262;
  color: white;
  animation: blink 1s linear infinite;
}

/* 

*/
.color_1 {
  color: #053262;
  font-size: 20px;
}

#cursor {
  border-left: 0.1em solid #053262;
  animation: blink 0.7s steps(1) infinite;
}


#services .slick-slide img {
  height: 350px;
  object-fit: cover;
}

.serviceoverlay {
  position: absolute;
  left: 0;
  bottom: 50px;
  padding: 10px 50px 10px 50px;

  background: #fff;
  border-radius: 0 7px 7px 0;
  transition: all 0.5s ease-out 0s;
}

/* .aboutimg:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
  
} */

.about1:hover,
.about2:hover,
.about3:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}


.testimonial-wrapper .testimonial-item:hover {
  background: #3f56a3;
}

.testimonial-wrapper .testimonial-item:hover .quote svg path {
  fill: #f46c02;
}


.about2 {
  height: 200px !important;
  position: absolute;
  top: -108px;
  border-radius: 30px;
  left: 8px;
}

.about1 {
  position: absolute;
  height: 200px !important;
  top: 40px;
  left: 304px;
  border-radius: 30px;

}

.about3 {
  height: 260px !important;
  position: absolute;
  /* width: 100%; */
  /* width: 310px; */
  top: -240px;
  left: 300px;
  /* height: 300px !important; */
  object-fit: contain;
  border-radius: 30px;

}

.aboutimgmobile {
  display: none;
}

.mainsliderimg img {
  width: 100% !important;
  height: 280px !important;
  position: relative !important;
  left: -120px !important;
  object-fit: contain !important;
}

@media (max-width:1200px) {
  .mainsliderimg img {
    margin: 10px !important;
    padding: 18px !important;
    width: 100% !important;
    height: 280px !important;
    position: relative !important;
    left: -120px !important;
    object-fit: contain !important;
  }

}

@media (max-width:992px) {
  .desktopimg {
    height: 350px;
  }

  .mainsliderimg img {
    width: 100% !important;
    height: 280px !important;
    position: relative !important;
    left: 0 !important;
    object-fit: contain !important;
  }

  .desktopimg .about3,
  .desktopimg .about1 {
    height: 150px !important;
  }

  .desktopimg .about3 {
    top: -10px !important;
  }

  .desktopimg .about2 {
    top: 50px;
  }

  .desktopimg .about1 {
    top: 170px;
  }

  .desktoporder1 {
    order: 2 !important;
  }

  .desktoporder2 {
    order: 1 !important;
  }

  .aboutimgmobile {
    display: block;
  }
}


/* .venturebox:hover {
  background-color: #f46c02 !important;
  transition: all ease-in-out 0.4s !important;
  color: white !important;
}

.venturebox:hover h6 {
  color: white;
  transition: all ease-in-out 0.4s !important;
} */

.venturebox i {
  color: #f46c02;

}

.aboutcards {
  margin-top: 130px;
}

.aboutcards .slick-slide {
  padding: 30px 0 !important;
  border: 0 !important;
}



.active-slide {
  /* Highlight effect */


  transform: scale(1.1);
  /* Slightly larger */
}

@media (max-width:560px) {


  .mainsliderimg img {
    width: 100% !important;
    height: 280px !important;
    position: relative !important;
    left: 0 !important;
    object-fit: contain !important;
  }

  .desktopimg .about3,
  .desktopimg .about1 {
    height: 130px !important;
    left: 170px;

  }

  .desktopimg {
    overflow: hidden;
  }


  .desktopimg .about3 {
    top: 10px !important;
  }

  .desktopimg .about2 {
    top: 80px;
    left: 0 !important;
    height: 120px !important;
  }

  .desktopimg .about1 {
    top: 170px;
  }
}